@import 'utils/normalize.css';
@import 'utils/normalize.css';
@import 'fonts.css';
@import 'typography.css';
@import 'components/button.css';
@import 'sections/top-bar.css';
@import 'sections/footer.css';
@import 'pages/App.css';
@import 'pages/Responsive.css';
@import 'utils/utils.css';
@import 'utils/prose.css';

:root {
	--pay-brand: 4 75% 48%;
	--pay-brand-darker: 4 75% 40%;

	--pay-green: 176 67% 12%;

	--pay-green-accent: 173 71% 28%;
	--pay-green-accent-darker: 173 71% 20%;

	--pay-tint: 174 27% 85%;

	--pay-coral: 16 100% 76%;

	--pay-salmon: 0 100% 76%;

	--pay-pink: 356 53% 79%;

	--pay-coal: 200, 17%, 17%;

	--pay-slate: 180 4% 52%;

	--pay-white: 0 0% 100%;
}

:root {
	--background: var(--pay-white);
	--foreground: var(--pay-coal);

	--muted: 210 40% 96.1%;
	--muted-foreground: 215.4 16.3% 46.9%;

	--popover: 0 0% 100%;
	--popover-foreground: 222.2 84% 4.9%;

	--card: 0 0% 100%;
	--card-foreground: 222.2 84% 4.9%;

	--border: 214.3 31.8% 91.4%;
	--input: 214.3 31.8% 91.4%;

	--brand: var(--pay-brand);
	--brand-darker: var(--pay-brand-darker);
	--brand-foreground: var(--pay-white);

	--primary: var(--pay-green);
	--primary-foreground: var(--pay-white);

	--primary-accent: var(--pay-green-accent);
	--primary-accent-darker: var(--pay-green-accent-darker);
	--primary-accent-foreground: var(--pay-white);

	--primary-tint: var(--pay-tint);
	--primary-tint-foreground: var(--pay-coal);

	--secondary: var(--pay-coral);
	--secondary-foreground: var(--pay-coal);

	--secondary-accent: var(--pay-salmon);
	--secondary-accent-foreground: var(--pay-coal);

	--secondary-tint: var(--pay-pink);
	--secondary-tint-foreground: var(--pay-coal);

	--accent: 210 40% 96.1%;
	--accent-foreground: 222.2 47.4% 11.2%;

	--destructive: 0 84.2% 60.2%;
	--destructive-foreground: 210 40% 98%;

	--ring: 215 20.2% 65.1%;

	--radius: 0.25rem;

	--font-serif: Ubuntu, system-ui, BlinkMacSystemFont, -apple-system, Segoe UI,
		Roboto, Oxygen, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
	scroll-behavior: smooth;
}

body {
	background-color: hsl(var(--background));
	color: hsl(var(--foreground));

	font-family: var(--font-serif);
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0;
}

a:hover {
	text-decoration: underline;
}

.prose a {
	color: hsl(var(--primary-accent));
	font-weight: 600;
}
