h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	display: block;
	line-height: 1.125;
	letter-spacing: -0.025em;
	position: relative;
	margin-bottom: 1rem;
}

h1,
.h1 {
	font-size: 2.7rem;
	font-weight: 200;
	text-transform: uppercase;
}

h2,
.h2 {
	font-size: 2rem;
	font-weight: 200;
	text-transform: none;
}

h3,
.h3 {
	font-size: 1.7rem;
	font-weight: 200;
	text-transform: none;
}

h1.line-left,
.h1.line-left,
h2.line-left,
.h2.line-left {
	margin-bottom: 4rem;
}

h1.line-left::after,
.h1.line-left::after,
h2.line-left::after,
.h2.line-left::after {
	content: ' ';
	width: 6rem;
	height: 0.3rem;
	background-color: hsl(var(--primary-accent));
	display: block;
	position: absolute;
	bottom: -2rem;
	left: 0;
	right: auto;
	transform: translateX(0);
	perspective: 0;
}

h1.line-center,
.h1.line-center,
h2.line-center,
.h2.line-center {
	margin-bottom: 4rem;
}

h1.line-center::after,
.h1.line-center::after,
h2.line-center::after,
.h2.line-center::after {
	content: ' ';
	width: 6rem;
	height: 0.3rem;
	background-color: hsl(var(--primary-accent));
	display: block;
	position: absolute;
	bottom: -2rem;
	left: 50%;
	right: auto;
	transform: translateX(-50%);
	perspective: 0;
}

@media only screen and (max-width: 500px) {
	h1,
	.h1 {
		font-size: 2rem !important;
	}
}
