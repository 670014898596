.about__hero {
	position: relative;
	min-height: 80vh;
	display: flex;
	align-items: center;
	color: hsl(var(--pay-white));
}

.about__hero > video,
.about__hero > img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.about__hero > img {
	z-index: -1;
}

.about__hero > video {
	z-index: 0;
}

.about__hero-text-container {
	position: relative;
	width: fit-content;
	display: grid;
	justify-items: center;
	gap: 1.5rem;
}

.about__hero-text-container img {
	width: 10rem;
}

.about__hero-text-container h2 {
	font-size: 2rem;
	font-weight: 700;
}

.about__hero-text-container p {
	max-width: 65ch;
}

.about__we-will-help {
	background-color: hsla(var(--primary-tint) / 0.25);
}

.about__we-will-help-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	gap: 2rem;
	justify-items: center;
	padding-top: 2rem;
}

.about__we-will-help-items > div img {
	display: block;
	margin: 0 auto 1.5rem auto;
	height: 4rem;
}

.about__we-will-help-items > div p {
	max-width: 50ch;
	margin-bottom: 1ch;
}

.about__accomplishments-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	gap: 2rem;
	justify-items: center;
	align-items: center;
	max-width: 65ch;
	margin: 0 auto;
}

.about__accomplishments-items > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
}

.about__accomplishments-items > div h3 {
	text-align: center;
	font-weight: 400;
	font-size: 1.125rem;
}

.about__accomplishments-items > div h3 strong {
	font-size: 1.75rem;
	line-height: 1.5;
}

.about__accomplishments-items > div img {
	width: 5rem;
	height: 5rem;
}
