:root {
	--top-bar-height: 56px;
}

header:has(.top-bar) {
	position: sticky;
	top: 0;
	z-index: 100;
	border-bottom: 1px;
	box-shadow: 0 2px 1rem 0 hsla(var(--pay-coal), 0.1);
	height: var(--top-bar-height);
}

.top-bar {
	position: relative;
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background-color: #fff;
}

.top-bar nav {
	display: flex;
	align-items: center;
}

.top-bar a {
	display: flex;
	align-items: center;
	text-align: center;
	padding: 0 1.5rem;
	height: 100%;
	font-weight: 700;
	font-size: 1rem;
}

.top-bar a:hover {
	background-color: hsl(var(--pay-coal), 0.05);
	text-decoration: none;
}

.top-bar a:has(.nav-logo) {
	padding: 0;
	height: var(--top-bar-height);
}

.top-bar a .nav-logo {
	height: var(--top-bar-height);
	width: auto;
}

button.hamburger {
	display: flex;
	flex-direction: column-reverse;
}

#hamburger-toggle {
	position: relative;
	height: var(--top-bar-height);
	width: var(--top-bar-height);
	transition: all 300ms ease-in-out;
	background-color: hsl(var(--pay-white));
	vertical-align: middle;
	align-self: self-end;
}

#hamburger-toggle:hover {
	background-color: hsla(var(--pay-coal), 0.05);
	cursor: pointer;
}

#hamburger-toggle span {
	display: block;
	position: absolute;
	height: 2px;
	width: 50%;
	background-color: hsl(var(--pay-coal));
	border-radius: 2px;
	opacity: 1;
	left: 25%;
	transform: rotate(0deg);
	perspective: 0;
	transition: all 300ms ease-in-out;
}

#hamburger-toggle span:nth-child(1) {
	top: 32%;
}

#hamburger-toggle span:nth-child(2),
#hamburger-toggle span:nth-child(3) {
	top: 47%;
}

#hamburger-toggle span:nth-child(4) {
	top: 62%;
}

.hamburger-menu-content {
	height: 100%;
	width: 100%;
	background-color: hsl(var(--pay-white));
	position: fixed;
	top: var(--top-bar-height);
	right: 0;
	transition: transform 300ms ease-in-out;
	padding-top: 2rem;
	transform: translateX(100%);
}

.hamburger-menu-content.accessibly-hidden {
	visibility: hidden;
}

.hamburger-menu-content li a {
	padding: 1rem 2rem;
}

.hamburger[aria-expanded='true'] + .hamburger-menu-content {
	transform: translateX(0);
}

button.hamburger[aria-expanded='true'] #hamburger-toggle span {
	background-color: hsl(var(--pay-coal));
}

button.hamburger[aria-expanded='true'] #hamburger-toggle:hover {
	background-color: hsla(var(--pay-coal), 0.1);
}

button.hamburger[aria-expanded='true'] #hamburger-toggle span:nth-child(1) {
	top: 18px;
	width: 0;
	left: 50%;
}

button.hamburger[aria-expanded='true'] #hamburger-toggle span:nth-child(2) {
	transform: rotate(45deg);
	perspective: 0;
}

button.hamburger[aria-expanded='true'] #hamburger-toggle span:nth-child(3) {
	transform: rotate(-45deg);
	perspective: 0;
}

button.hamburger[aria-expanded='true'] #hamburger-toggle span:nth-child(4) {
	top: 18px;
	width: 0;
	left: 50%;
}

.lang-choice {
	display: flex;
	align-items: center;
}

.lang-choice button {
	padding: 4px;
	font-weight: 200;
}

.lang-choice button:hover {
	cursor: pointer;
	background-color: hsl(var(--pay-coal), 0.05);
}

.lang-choice button.selected {
	padding: 4px;
	text-decoration: underline;
	font-weight: 600;
}

@media only screen and (max-width: 700px) {
	.top-bar {
		justify-content: space-between;
	}
}
