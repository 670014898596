.footer {
	background-color: hsl(var(--primary));
	color: rgba(255, 255, 255, 0.8);
	position: relative;
	color: hsl(var(--primary-foreground));
}

.footer-columns {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 2rem;
}

.footer-meta-wrapper {
	padding-top: 4rem;
	display: flex;
	justify-content: flex-end;
}

.footer-meta {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

@media only screen and (max-width: 1024px) {
	.footer-columns {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media only screen and (max-width: 700px) {
	.footer-columns {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media only screen and (max-width: 500px) {
	.footer-columns {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.footer-columns > div {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.footer-columns h3 {
	font-size: 1.5rem;
	margin-bottom: 0.75rem;
	font-weight: 600;
}

.footer-columns h4 {
	font-size: 1.25rem;
	margin-bottom: 0.75rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-columns p,
.footer-columns li {
	margin-top: 0.25rem;
}

.footer-logo {
	width: 100%;
	max-width: 80px;
}
