.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	justify-items: center;

	font-size: 1rem;
	color: rgba(255, 255, 255, 1);
	padding: 0 2rem;
	height: 3rem;

	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
	border-radius: var(--radius);

	transition-property: color, background-color, border-color,
		text-decoration-color, fill, stroke;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.15s;
}

.button:hover {
	text-decoration: none;
}

.button.button--sm {
	padding: 0 1.5rem;
	height: 2.75rem;
}

.button.button--primary {
	color: hsl(var(--primary-accent-foreground));
	background-color: hsl(var(--primary-accent));
}

.button.button--primary:hover {
	background-color: hsl(var(--primary-accent-darker));
}

.button.button--primary {
	background-color: hsl(var(--primary-accent));
}

.button.button--primary.button--outline {
	background-color: transparent;
	color: hsl(var(--primary-accent));
	border: 2px solid hsl(var(--primary-accent));
}

.button.button--primary.button--outline:hover {
	color: hsl(var(--primary-accent-foreground));
	background-color: hsl(var(--primary-accent));
}

.button.button--fixed-cookie-consent {
	position: fixed;
	z-index: 1000;
	bottom: 0;
	left: 0;
	border-radius: 0 9999px 0 0;
	padding-right: 2rem;
}
