@font-face {
	font-family: 'Ubuntu';
	src: url(../fonts/Ubuntu-Light.woff) format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Ubuntu';
	src: url(../fonts/Ubuntu-Light.woff) format('woff');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'Ubuntu';
	src: url(../fonts/Ubuntu-Medium.woff) format('woff');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'Ubuntu';
	src: url(../fonts/Ubuntu-MediumItalic.woff) format('woff');
	font-style: italic;
	font-weight: bold;
}
