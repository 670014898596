@import './about.css';

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.App main {
	flex: 1 1 0;
}

.home-page .hero {
	min-height: 80vh;
	display: flex;
	justify-content: center;
	text-align: center;
	color: hsl(var(--pay-white));

	background: url(../../images/pages/home/hero-image.jpg) center center/cover
		no-repeat;
}

.home-page .hero p {
	margin-bottom: 1.5rem;
}

#usp {
	background-color: hsl(var(--primary-tint));
	color: hsl(var(--primary-tint-foreground));
	display: block;
}

#usp .usp-container {
	display: flex;
	align-content: stretch;
	overflow: visible;
	position: relative;
	width: 100%;
	padding-top: 5rem;
	padding-bottom: 5rem;
}

#usp .usp-container > div {
	padding: 1rem;
	width: 100%;
}

#usp .usp-container h3 {
	font-size: 1.5rem;
	line-height: 1.375;
	margin-top: 0;
}

#usp .usp-container svg {
	display: inline-block;
	margin-right: 10px;
	width: 1em;
	height: 1em;
	vertical-align: -0.125em;
}

#testimonials .testimonials-container {
	display: flex;
	gap: 5rem;
	align-items: center;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	align-content: stretch;
	overflow: visible;
	position: relative;
	width: 100%;
	padding: 5rem 0;
}

#testimonials .testimonials-container div {
	height: auto;
	vertical-align: middle;
}

.figure-circle {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.figure-circle::before {
	content: ' ';
	position: absolute;
	width: 80%;
	height: 80%;
	top: 10%;
	left: 10%;
	border-radius: 50%;
	background-color: rgba(77, 182, 172, 0.05);
	z-index: 0;
}

.figure-circle figure {
	text-align: center;
	z-index: 10;

	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	padding-left: 1rem;
	display: flex;
}

/* .figure-circle figure:nth-child(1) {
  transform: translate3d(10%, -110%, 0);
  perspective: 0;
}

.figure-circle figure:nth-child(2) {
  transform: translate3d(190%, -65%, 0);
  perspective: 0;
}

.figure-circle figure:nth-child(3) {
  transform: translate3d(80%, 10%, 0);
  perspective: 0;
} */

.figure-circle figure .graphic {
	width: 50%;
	height: auto;
}

.figure-circle figure figcaption {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	margin-left: 1rem;
	text-align: left;
	line-height: 1.5;
}

.figure-circle figure figcaption p {
	padding: 0;
	margin: 0;
}

.figure-circle figure figcaption img {
	margin-top: 0.25rem;
	width: 6rem;
}

.img-circle {
	height: 5rem;
	aspect-ratio: 1;
	vertical-align: middle;
	border-radius: 50%;
	box-shadow: 0.25rem 0.25rem 0.35rem 0 rgba(0, 0, 0, 0.25);
	border-width: 2px;
	border-color: #4db6ac;
}

#perks-table {
	max-width: 1024px;
	padding: 5rem 0;
}

#perks-table h2 {
	text-align: center;
	margin-top: 0;
}

#perks-table .top-section p {
	color: hsl(var(--muted-foreground));
	text-align: center;
	margin-bottom: 1.5rem;
}

#perks-table .loan-example {
	color: #7c7c7b;
}

.perks-table-item {
	display: flex;
	padding: 1.5rem 1rem;
}

.perks-table-item:not(:last-child) {
	border-bottom: solid 1px hsla(var(--pay-coal), 0.1);
}

.perks-table-item h3 {
	margin: 0 0 1rem 0;
}

.perks-table-item > * {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	padding: 1rem;
}

.perks-table-item :nth-child(1) {
	flex-basis: 33.3333%;
	max-width: 33.3333%;
}

.perks-table-item :nth-child(2) {
	flex-basis: 66.6667%;
	max-width: 66.6667%;
}

.perks-table-item h3 {
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

#pay-hero {
	display: flex;
	width: 100%;
}

#pay-hero > div {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
	flex-basis: 50%;
	max-width: 50%;
}

#pay-hero .phone-bg {
	background-image: url('../../images/pay-center-console-cropped-center.jpg');
	background-size: cover;
	background-position: center center;
}

#pay-hero .mymoney-pay-info {
	background-color: hsl(var(--primary-tint) / 0.25);
	color: hsl(var(--primary-tint-foreground));
	font-weight: 400;
}

#pay-hero .mymoney-pay-info p {
	max-width: 80ch;
}

#pay-hero .mymoney-pay-info p:has(.button) {
	padding-top: 2rem;
}

.pay-page {
	letter-spacing: normal;
}

.pay-page h2 {
	font-weight: 700;
	font-size: 1.9rem;
}

#pay-page-hero {
	position: relative;
	height: 80vh;
	width: 100%;
	display: grid;
	align-items: center;
}

#pay-page-hero > video,
#pay-page-hero > img {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#pay-page-hero > video {
	z-index: 0;
}

#pay-page-hero > img {
	z-index: -1;
}

#pay-page-hero > .video-text-container {
	position: relative;
	display: grid;
	gap: 2rem;
	justify-items: center;
	width: fit-content;
	text-align: center;
}

#pay-page-hero > .video-text-container h2 {
	color: hsl(var(--pay-white));
}

#pay-page-hero > .video-text-container img {
	height: 3rem;
}

.pay-page .hero-buttons-div {
	display: flex;
	gap: 1rem;
}

#more-about-pay {
	display: flex;
	gap: 2rem;
}

#more-about-pay > div {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
}

#more-about-pay p {
	margin-bottom: 1rem;
}

#more-about-pay ul {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-bottom: 1rem;
}

#more-about-pay ul li {
	display: flex;
	align-items: center;
	font-weight: 600;
}

#more-about-pay ul li:not(:first-child) {
	margin-top: 0.75rem;
}

#more-about-pay ul li img {
	height: 1.5rem;
	margin-right: 0.75rem;
}

#more-about-pay .pitch-image {
	align-self: center;
	padding: 0;
}

#more-about-pay .pitch-image img {
	width: 100%;
	height: auto;
	vertical-align: middle;
}

#mymoney-unique {
	text-align: center;
	display: grid;
}

#mymoney-unique h2 {
	font-weight: 600;
}

#mymoney-unique .unique-top-section {
	padding: 2rem 0;
}

#mymoney-unique .thumbs-section {
	display: flex;
	max-width: 42rem;
	margin: 0 auto;
	padding: 2rem 0;
}

#mymoney-unique .thumbs-section div {
	position: relative;
	padding: 2rem;
}

#mymoney-unique h3 {
	font-size: 1.2rem;
	font-weight: 600;
	margin-top: 0;
}

#mymoney-unique .thumbs-section p {
	margin: 0;
}

#mymoney-unique .thumbs-section .pay-border {
	background-color: hsla(var(--primary-tint) / 0.25);
	border-radius: 8px;
	border: solid 2px hsl(var(--primary-accent));
}

#mymoney-unique .thumbs-section .pay-border p {
	font-weight: 600;
	color: hsl(var(--primary-accent));
}

#mymoney-unique .bottom-section {
	display: flex;
	max-width: 56rem;
	margin: 0 auto;
	padding: 2rem 0;
}

#mymoney-unique .bottom-section h3 {
	margin-top: 8px;
}

#mymoney-unique .bottom-section p {
}

#mymoney-unique .bottom-section div {
	align-self: auto;
	position: relative;
	padding: 2rem;
}

#apply-pay {
	padding: 5rem 0;
	border-top: 1px solid hsla(var(--pay-coal), 0.1);
	border-bottom: 1px solid hsla(var(--pay-coal), 0.1);
	background-color: hsl(var(--primary-tint) / 0.25);
}

#apply-pay .fields-header {
	text-align: center;
}

#apply-pay .fields-header a {
	color: hsl(var(--primary-accent));
	font-weight: 600;
}

#apply-pay .fields-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	--_gap: 2rem;
	gap: var(--_gap);
	margin-top: var(--_gap);
	max-width: 56rem;
}

#apply-pay .fields-container div {
	flex-basis: calc(50% - var(--_gap) / 2);
}

#apply-pay .fields-container div:has(.button) {
	align-self: center;
	text-align: center;
	max-width: 200px;
}

#apply-pay input[type='text'],
#apply-pay input[type='email'],
#apply-pay input[type='number'],
#apply-pay input[type='tel'] {
	padding: 1rem;
	display: block;
	width: 100%;
	min-width: 0;
	font-weight: bold;
	border-radius: 0.25em;
	border-width: 1px;
	border-color: hsla(var(--pay-coal), 0.1);
	line-height: normal;
	cursor: text;
	appearance: none;
	outline: none;
}

#apply-pay input[type='text']::placeholder,
#apply-pay input[type='email']::placeholder,
#apply-pay input[type='number']::placeholder,
#apply-pay input[type='tel']::placeholder {
	color: inherit;
	font-style: normal;
	opacity: 0.4;
	font-weight: normal;
}

#apply-pay input[type='text']:focus,
#apply-pay input[type='email']:focus,
#apply-pay input[type='number']:focus,
#apply-pay input[type='tel']:focus {
	box-shadow: none;
	outline: none;
}

#apply-pay .form-submit-success {
	color: #157a6f;
	font-weight: bold;
	font-size: 16px;
}

#suitable-for-my-business {
	text-align: center;
	padding: 5rem 0;
}

#suitable-for-my-business .mymoney-car {
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid hsla(var(--pay-coal), 0.1);
	border-radius: 6px;
	max-width: 25rem;
	min-height: 20rem;
}

#suitable-for-my-business .mymoney-car .mymoney-car-top {
	background-color: rgba(216, 230, 219, 0.25);
	padding: 1.5rem;
}

#suitable-for-my-business .mymoney-car ul {
	padding: 1.5rem;
}

#suitable-for-my-business .mymoney-car h4 {
	font-size: 1.5rem;
	margin-top: 5px;
}

#suitable-for-my-business .mymoney-car ul li {
	list-style: none;
	text-align: left;
	font-weight: 600;
}

#suitable-for-my-business .mymoney-car ul li img {
	vertical-align: top;
	padding-right: 10px;
}

#what-do-i-get {
	text-align: center;
	padding: 5rem 0;
}

#what-do-i-get h2 {
	margin-bottom: 40px;
	font-weight: 600;
}

.what-do-i-get-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;
}

.what-do-i-get-items > div {
	max-width: 65ch;
	padding: 2rem;
}

#what-do-i-get h3 {
	font-weight: 600;
	font-size: 1.2rem;
	margin-top: 10px;
}

.contact-header {
	padding: 5rem 0;
}

.contact-container {
	padding-top: 2rem;
	padding-bottom: 1rem;
	display: flex;
	align-content: stretch;
	overflow: visible;
	position: relative;
	width: 100%;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0;
	border-style: solid;
	border-color: hsla(var(--pay-coal), 0.1);
}

.contact-container > div {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	padding: 1rem;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 25%;
	max-width: 25%;
}

.information-page .employees {
	display: flex;
	flex-wrap: wrap;
	padding: 2rem 0;
	border-bottom-width: 1px;
}

.information-page .employees > div {
	flex: 1 1 auto;
	align-self: auto;
	position: relative;
	padding: 1rem;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 25%;
}

.information-page .employees .name {
	font-weight: 700;
	color: #111;
	margin-bottom: 0;
}

.align-center h1 {
	text-align: center;
}

.information-page p {
	font-weight: 200;
}

.information-page h4 {
	font-weight: 600;
}

.information-page a {
	font-weight: 600;
	color: hsl(var(--primary-accent));
	text-decoration: none;
}

main:has(.polygon-footer-background) {
	background-image: url('../../images/bg-polygons-footer.png');
	background-size: 100% auto;
	background-position: bottom;
	background-repeat: no-repeat;
}
