@media only screen and (max-width: 1024px) {
	#perks-table .perks-table-item {
		flex-direction: column;
	}

	#perks-table .perks-table-item > * {
		flex-basis: 100% !important;
		max-width: 100% !important;
		padding: 0;
	}

	#more-about-pay {
		display: flex;
		flex-direction: column;
	}

	#more-about-pay div {
		flex-basis: 100% !important;
	}

	#more-about-pay ul {
		padding: 0 10px;
	}

	#more-about-pay .hero-buttons-div {
		display: block;
	}

	.contact-container {
		flex-wrap: wrap;
	}

	.contact-container div {
		flex-basis: 50% !important;
		max-width: 50%;
	}

	.employees {
		flex-wrap: wrap;
	}

	.employees div {
		flex-basis: 50% !important;
		max-width: 50%;
	}

	#testimonials .testimonials-container {
		flex-direction: column;
		gap: 2rem;
	}

	#usp .usp-container {
		flex-direction: column;
		text-align: center;
	}
}

@media only screen and (max-width: 700px) {
	h1.complaint-handling-header {
		font-size: 2rem !important;
	}

	#usp .usp-container > div {
		flex-basis: 100% !important;
		max-width: 100% !important;
	}

	#testimonials .sub-700 {
		text-align: center;
	}

	#pay-hero {
		flex-direction: column;
		text-align: center;
	}

	#pay-hero > * {
		flex-basis: 100% !important;
		max-width: 100% !important;
	}

	#pay-hero .phone-bg {
		min-height: 300px;
	}

	#apply-pay .fields-container {
		flex-direction: column;
	}

	#apply-pay .fields-container div {
		flex-basis: 100% !important;
		max-width: 100% !important;
	}

	#mymoney-unique {
		text-align: center;
	}

	#mymoney-unique .bottom-section {
		display: block;
	}

	#mymoney-unique .thumbs-section {
		display: block;
	}
}

@media only screen and (max-width: 500px) {
	h1.complaint-handling-header {
		font-size: 1.5rem !important;
	}

	#testimonials figcaption {
		white-space: normal;
	}

	#pay-page-hero .hero-buttons-div {
		flex-direction: column;
	}

	#mymoney-unique {
		text-align: center;
	}

	.contact-container {
		flex-direction: column;
		text-align: center;
	}

	.contact-container div {
		flex-basis: 100% !important;
		max-width: 100%;
	}

	.employees {
		flex-direction: column;
		text-align: center;
	}

	.employees div {
		flex-basis: 100% !important;
		max-width: 100%;
	}
}
