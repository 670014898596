.max-w-prose {
	max-width: 65ch;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.self-center {
	align-self: center;
}

.thin {
	font-weight: 200;
}

.text-align-center {
	text-align: center;
}

.grid-container {
	display: flex;
}

.grid-container > div {
	flex-basis: 33.3333%;
	max-width: 33.3333%;

	align-self: auto;
	box-sizing: border-box;
	position: relative;

	flex-grow: 0;
	flex-shrink: 0;
}

.grid-container > * > div {
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 0;
	padding-right: 0;
}

.container {
	margin: 0 auto;
	max-width: 42rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

@media (min-width: 1024px) {
	.container {
		max-width: 80rem;
		padding: 0 2rem;
	}
}

.font-semibold {
	font-weight: 600;
}

.py-20 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

.uppercase {
	text-transform: uppercase;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
	border-color: rgb(229 231 235);
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
	border-color: rgb(209 213 219);
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
	border-right-width: 1px;
	border-left-width: 1px;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
	border-top-width: 1px;
	border-bottom-width: 1px;
}
